import JunoLogo from 'Assets/images/brand_juno_logo.png'
import TCHLogo from 'Assets/images/brand_tch_logo.png'
import KFM from 'Assets/images/init_Image.png'

type BrandVars = {
  logo: React.ElementRef<any>
  url: string
  color: string
}

export const brandVars = (): BrandVars => {
  let kingfoodVars = {
    logo: KFM,
    url: `${process.env.REACT_APP_COMPANY_WEBSITE}`,
    color: '#ea7f23',
  }
  switch (process.env.REACT_APP_COMPANY_NAME) {
    case 'kingfood':
      return kingfoodVars
    case 'thecoffeehouse':
      return {
        logo: TCHLogo,
        url: `${process.env.REACT_APP_COMPANY_WEBSITE}`,
        color: '#ea7f23',
      }
    default:
      return kingfoodVars
  }
}
