import axios from '../Utils/axios'
import { CancelTokenSource } from 'axios'

const PRODUCTS = '/products'

export async function uploadProductImage({ image, config = {} }) {
  const formData = new FormData()
  formData.append('images', image)
  const resp = await axios.post(
    `${PRODUCTS}/images/upload?company=${process.env.REACT_APP_COMPANY_NAME}`,
    formData,
    config
  )

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}

export async function searchProducts(keyword) {
  const resp = await axios.get(`${PRODUCTS}/search`, {
    params: { company: process.env.REACT_APP_COMPANY_NAME, keyword, limit: 50 },
  })

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}

export async function createProductDimension({ data, cancelTokenSource }) {
  const resp = await axios.post(`products/overview/filters`, data, {
    cancelToken: cancelTokenSource.token,
  })
  // error handling
  if (!resp.data) {
    throw resp
  }

  return resp.data
}

export async function getDimensionFromUuid(filterUuid) {
  const resp = await axios.get(`products/overview/filters?filter_uuid=${filterUuid}`)
  // error handling
  if (!resp.data) {
    throw resp
  }

  return resp.data
}
