import axios from 'Utils/axios'

import { Criteria } from 'DimensionsFiltersTypes'
import { CancelTokenSource } from 'axios'
const ENDPOINT = '/customer-profiles'

export async function getBrandFilterList() {
  const resp = await axios.get('/products/analytics/filters')
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}

export async function getCategoryList() {
  const resp = await axios.get('/products/overview/categories?company=kingfood')
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}

export async function getProductListingCategoryList({ source }: { source?: string }) {
  const resp = await axios.get(`/products/overview/categories?company=kingfood`, {
    params: { source },
  })
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}

export async function getProduct360Test({
  id,
  type,
  frequency,
  cancelTokenSource,
}: {
  id?: number
  type?: string
  frequency?: string
  cancelTokenSource?: CancelTokenSource
}) {
  const resp = await axios.get(`${ENDPOINT}/124198/analytics/charts`, {
    params: { analytic_type: type, frequency },
    cancelToken: cancelTokenSource?.token,
  })

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}

export async function getProduct360KeyMetrics({
  from_date,
  to_date,
  dimensions = [],
  cancelTokenSource,
}: {
  from_date?: string
  to_date?: string
  dimensions?: Criteria[]
  cancelTokenSource?: CancelTokenSource
}) {
  const resp = await axios.get(`/products/product360/analytics/key-metrics`, {
    params: { from_date, to_date, dimensions: JSON.stringify(dimensions) },
    cancelToken: cancelTokenSource?.token,
  })

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}

export async function getProductDetailList(
  category_id?: any,
  source?: string,
  page?: number,
  per_page?: number,
  limit?: number,
  brands?: any,
  suppliers?: any,
  from_date?: any,
  to_date?: any,
  keyword?: string,
  order_by?: string,
  cancelTokenSource?: CancelTokenSource
) {
  const resp = await axios.get(`/products/analytics`, {
    params: {
      category_id,
      source,
      page,
      per_page,
      limit,
      brands,
      suppliers,
      from_date,
      to_date,
      keyword,
      order_by,
    },
    cancelToken: cancelTokenSource?.token,
  })
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}

export async function getProduct360DetailInfos({
  id,
  frequency,
  from_date,
  to_date,
  cancelTokenSource,
}: {
  id?: any
  frequency?: string
  from_date?: string
  to_date?: string
  cancelTokenSource?: CancelTokenSource
}) {
  const resp = await axios.get(`/products/product360/analytics/infos`, {
    params: {
      frequency,
      barcode: id,
      from_date,
      to_date,
      is_export: false,
    },
    cancelToken: cancelTokenSource?.token,
  })
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}

export async function getProduct360V2KeyMetrics({
  barcode,
  analytic_type,
  frequency,
  from_date,
  to_date,
  cancelTokenSource,
}: {
  barcode?: string
  analytic_type?: string
  frequency?: string
  from_date?: string
  to_date?: string
  cancelTokenSource?: CancelTokenSource
}) {
  const resp = await axios.get(`/products/product360/analytics/key-metrics`, {
    params: {
      frequency,
      barcode,
      from_date,
      to_date,
      analytic_type: analytic_type,
      is_export: false,
    },
    cancelToken: cancelTokenSource?.token,
  })

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}

export async function getProduct360Charts({
  barcode,
  type,
  from_date,
  to_date,
  dimensions,
  frequency,
  cancelTokenSource,
}: {
  from_date?: string
  to_date?: string
  dimensions?: Criteria[]
  barcode?: string
  type?: string
  frequency?: string
  cancelTokenSource?: CancelTokenSource
}) {
  const resp = await axios.get(`/products/product360/analytics/charts`, {
    params: {
      from_date,
      to_date,
      dimensions: JSON.stringify(dimensions),
      frequency,
      barcode,
      analytic_type: type,
    },
    cancelToken: cancelTokenSource?.token,
  })

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}

export async function getProductOverviewCharts({
  from_date,
  to_date,
  type,
  dimensions = [],
  frequency,
  cancelTokenSource,
  filter_uuid,
  source,
}: {
  from_date?: string
  to_date?: string
  type?: string
  dimensions?: Criteria[]
  frequency?: string
  filter_uuid?: string
  source?: string
  cancelTokenSource?: CancelTokenSource
}) {
  const resp = await axios.get(`/products/overview/analytics/charts`, {
    params: {
      from_date,
      to_date,
      analytic_type: type,
      frequency,
      dimensions: JSON.stringify(dimensions),
      filter_uuid,
      source,
    },
    cancelToken: cancelTokenSource?.token,
  })

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}

export async function getProduct360FunnelData({
  barcode,
  cancelTokenSource,
}: {
  barcode?: string
  cancelTokenSource?: CancelTokenSource
}) {
  const resp = await axios.get(`/products/product360/analytics/funnels`, {
    params: {
      barcode,
    },
    cancelToken: cancelTokenSource?.token,
  })

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}

export async function getProduct360ProductsList({
  from_date,
  to_date,
  frequency,
  barcode,
  id,
  type,
  cancelTokenSource,
}: {
  from_date?: string
  to_date?: string
  frequency?: string
  barcode?: string
  id?: number
  type?: string
  cancelTokenSource?: CancelTokenSource
}) {
  const resp = await axios.get(`/products/product360/analytics/recommends`, {
    params: { analytic_type: type, barcode: barcode, frequency, from_date, to_date, id },
    cancelToken: cancelTokenSource?.token,
  })

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}

// Product v2 Overview

export async function getProductOverviewKeyMetrics({
  from_date,
  to_date,
  dimensions,
  frequency,
  filter_uuid,
  source,
  cancelTokenSource,
}: {
  frequency?: string
  from_date?: string
  to_date?: string
  dimensions?: Criteria[]
  filter_uuid?: string
  cancelTokenSource?: CancelTokenSource
  source?: string
}) {
  const resp = await axios.get(`/products/overview/analytics/key-metrics`, {
    params: {
      frequency,
      from_date,
      to_date,
      dimensions: JSON.stringify(dimensions),
      filter_uuid,
      source,
    },
    cancelToken: cancelTokenSource?.token,
  })

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}

export async function getProductOverviewTables({
  from_date,
  to_date,
  type,
  category_id,
  dimensions = [],
  frequency = 'daily',
  page,
  per_page,
  order_by,
  cancelTokenSource,
  filter_uuid,
  source,
}: {
  from_date?: string
  to_date?: string
  type?: string
  category_id?: string
  dimensions?: Criteria[]
  frequency?: string
  page?: number
  per_page?: number
  order_by?: string
  cancelTokenSource?: CancelTokenSource
  filter_uuid?: string
  source?: string
}) {
  const resp = await axios.get(`/products/overview/analytics/ranking-tables`, {
    params: {
      from_date,
      to_date,
      analytic_type: type,
      category_id: category_id,
      page,
      per_page,
      frequency,
      order_by: order_by,
      dimensions: JSON.stringify(dimensions),
      filter_uuid,
      source,
    },
    cancelToken: cancelTokenSource?.token,
  })

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}

export async function getProductOverviewRevenueTables({
  from_date,
  to_date,
  type,
  category_id,
  dimensions = [],
  frequency = 'daily',
  page,
  per_page,
  filter_uuid,
  cancelTokenSource,
  source,
}: {
  from_date?: string
  to_date?: string
  type?: string
  category_id?: string
  dimensions?: Criteria[]
  frequency?: string
  page?: number
  per_page?: number
  filter_uuid?: string
  cancelTokenSource?: CancelTokenSource
  source?: string
}) {
  const resp = await axios.get(`/products/overview/analytics/tables`, {
    params: {
      from_date,
      to_date,
      analytic_type: type,
      category_id: category_id,
      page,
      per_page,
      frequency,
      order_by: 'revenue',
      dimensions: JSON.stringify(dimensions),
      filter_uuid,
      source,
    },
    cancelToken: cancelTokenSource?.token,
  })

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}

export async function getProductOverviewMetricTables({
  from_date,
  to_date,
  type,
  dimensions,
  frequency,
  cancelTokenSource,
  filter_uuid,
  source,
}: {
  from_date?: string
  to_date?: string
  type?: string
  dimensions?: Criteria[]
  frequency?: string
  cancelTokenSource?: CancelTokenSource
  filter_uuid?: string
  source?: string
}) {
  const resp = await axios.get(`/products/overview/analytics/tables`, {
    params: {
      from_date,
      to_date: to_date,
      analytic_type: type,
      order_by: 'revenue',
      frequency: frequency,
      dimensions: JSON.stringify(dimensions),
      filter_uuid,
      source,
    },
    cancelToken: cancelTokenSource?.token,
  })

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}

export async function getProductCategoryList({
  analytic_type,
  source,
  cancelTokenSource,
}: {
  analytic_type?: string
  source?: string
  cancelTokenSource?: CancelTokenSource
}) {
  const resp = await axios.get(`/products/overview/categories`, {
    params: { analytic_type: analytic_type, source },
    cancelToken: cancelTokenSource?.token,
  })

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}

export async function getPivotCateList({
  keyword,
  cancelTokenSource,
}: {
  keyword?: string
  cancelTokenSource?: CancelTokenSource
}) {
  const resp = await axios.get(`/sale-channels/overview/products`, {
    params: { keyword },
    cancelToken: cancelTokenSource?.token,
  })

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}
export async function getProductPivotCateList({
  keyword,
  cancelTokenSource,
}: {
  keyword?: string
  cancelTokenSource?: CancelTokenSource
}) {
  const resp = await axios.get(`/products/overview/products`, {
    params: { keyword },
    cancelToken: cancelTokenSource?.token,
  })

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}

export async function getProduct360SupplierListing({
  barcode,
  from_date,
  to_date,
  type,
  dimensions = [],
  frequency = 'daily',
  cancelTokenSource,
}: {
  barcode?: string
  from_date?: string
  to_date?: string
  type?: string
  dimensions?: Criteria[]
  frequency?: string
  cancelTokenSource?: CancelTokenSource
}) {
  const resp = await axios.get(`/products/product360/analytics/tables`, {
    params: {
      barcode,
      from_date,
      to_date,
      analytic_type: type,
      frequency,
      dimensions: JSON.stringify(dimensions),
    },
    cancelToken: cancelTokenSource?.token,
  })

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}
