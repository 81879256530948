import axios from 'Utils/axios'

const CAMPAIGN_ENDPOINT = '/store-farming/template_messages/campaign_parameter'
const DIMENSION_ENDPOINT = '/campaigns/dimensions/get-all'
const DIMENSION_DETAIL_ENDPOINT = '/campaigns/dimensions/format'

export async function getCampaignDynamicParams(audience_segment_id, campaign_id) {
  const resp = await axios.get(
    `${CAMPAIGN_ENDPOINT}?audience_segment_id=${audience_segment_id}${
      campaign_id ? `&campaign_id=${campaign_id}` : ''
    }`
  )

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}

export async function getAllDimension() {
  const resp = await axios.get(`${DIMENSION_ENDPOINT}`)

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}

export async function getDimensionDetail(dimension_code) {
  const resp = await axios.get(`${DIMENSION_DETAIL_ENDPOINT}?dimension_code=${dimension_code}`)

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}
