import axios from 'Utils/cj-axios'

export const getZnsTemplateByIdNew = async () => {
  const resp = await axios.get(`/zalo/zns/templates`)
  if (!resp.data) {
    throw resp
  }
  return { data: resp.data }
}

export const getParameterSegment = async () => {
  const resp = await axios.get(`/customer-journeys/parameters`)
  if (!resp.data) {
    throw resp
  }
  return { data: resp.data }
}
