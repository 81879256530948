import { colors } from 'DesignSystem'

export const colorScheme = ['#1F447B', '#EA8032']

// CHART COLORS
export const COLOR_PALLETTE = [
  colors.systemColor.blue_6,
  colors.systemColor.blue_3,
  colors.systemColor.cyan_6,
  colors.systemColor.purple_4,
  colors.systemColor.magenta_4,
  colors.systemColor.orange_4,
  colors.systemColor.blue_2,
  colors.systemColor.gold_4,
  colors.systemColor.red_4,
  colors.systemColor.gray_4,
  colors.systemColor.gray_10,
  colors.systemColor.gray_8,
  colors.systemColor.gray_6,
  colors.systemColor.gray_4,
  colors.systemColor.gray_2,
  colors.systemColor.green_4,
  colors.systemColor.green_6,
  colors.systemColor.green_8,
  colors.systemColor.green_10,
  colors.systemColor.lime_3,
  colors.systemColor.lime_5,
  colors.systemColor.lime_7,
  colors.systemColor.platinum_6,
  colors.systemColor.platinum_8,
]

// Campaign
export const CAMPAIGN_STEPS = [
  [{ name: 'Setting', code: 'setting' }],
  [{ name: 'Audience', code: 'audience' }],
  [{ name: 'Products', code: 'products' }],
  [{ name: 'Placements', code: 'placements' }],
  [{ name: 'Creative Contents', code: 'creative_content' }],
  [{ name: 'Schedule', code: 'schedule' }],
]

export const KARIBA_PRODUCT_SCHEMA = [
  {
    title: 'Image',
    dataIndex: 'Image',
    key: 'Image',
  },
  {
    title: 'Name',
    dataIndex: 'Title',
    key: 'Title',
  },
  {
    title: 'Price',
    dataIndex: 'Price',
    key: 'Price',
  },
]

export const CHANNEL_CODES = {
  post_engagement: 'post_engagement',
  link_clicks: 'link_clicks',
  web_push: 'web_push',
  kaipass: 'kaipass',
  sms: 'sms',
  email: 'email',
  toast: 'toast',
  harasocial: 'harasocial',
}

export const CAMPAIGN_PLACEMENT_CODES = [
  CHANNEL_CODES.post_engagement,
  CHANNEL_CODES.link_clicks,
  CHANNEL_CODES.web_push,
  CHANNEL_CODES.kaipass,
  CHANNEL_CODES.harasocial,
]

export const FB_PLACEMENT_CODES = [CHANNEL_CODES.post_engagement, CHANNEL_CODES.link_clicks]

export const NON_PRODUCT_PLACEMENT_CODES = [
  CHANNEL_CODES.post_engagement,
  CHANNEL_CODES.link_clicks,
  CHANNEL_CODES.web_push,
  CHANNEL_CODES.kaipass,
  CHANNEL_CODES.email,
  CHANNEL_CODES.harasocial,
]

export const CAN_START_STATUS = ['waiting', 'paused', 'stopped']

export const CAN_PAUSE_STATUS = ['running']

export const CAN_DELETE_STATUS = ['init', 'stopped']

export const CAN_PLAY_PAUSE_STATUS = ['init']

export const ANALYTIC_METRICS_CODES = [
  'total_customer',
  'total_revenue',
  'total_bill',
  'total_item',
  'aov',
  'avg_price',
  'revenue_per_customer',
  'item_per_customer',
  'bill_per_customer',
  'discount_rate',
  // 'number_of_users',
  // 'aov',
  // 'revenue',
  // 'total_bills',
  // 'total_items',
  // 'transactions',
  // 'clicks',
  // 'cr',
  // 'aov',
]

export const ANALYTIC_CHARTS_CODES = [
  'customer_by_ltv',
  'customer_by_spending',
  'customer_by_type',
  'customer_by_membership',
  'customer_by_item_range',
  'customer_by_order_range',
  'customer_by_aov_range',
  'customer_by_cluster',
  'customer_by_life_cycle',
  'customer_by_city',
]

export const CUSTOMER_360_COLUMNS = [
  'name',
  'customer_id',
  'tracking_id',
  'membership',
  'ltv',
  'life_cycle',
  'cluster',
]

export const LABEL_CHART = {
  discount_ratio: '% Discount',
  gross_profit_ratio: '% Gross Profit',
  item_per_order: 'Item/Order (Avg)',
}
