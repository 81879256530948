import axios from 'Utils/axios'

const CAMPAIGN_ENDPOINT = '/campaigns'

export async function getCreatedTags() {
  const resp = await axios.get(`/campaign-tags/`)

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}

export async function createTag(content) {
  const resp = await axios.post(`/campaign-tags/`, content)

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}

export async function updateTag(tag_id, contents) {
  const resp = await axios.put(`/campaign-tags/${tag_id}`, contents)

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}

export async function searchTag(search_keyword) {
  const resp = await axios.get(`/campaign-tags/search?keyword=${search_keyword}`)

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}

export async function addTagToCampaign({ campaign_id, tag_ids }) {
  const resp = await axios.post(`${CAMPAIGN_ENDPOINT}/${campaign_id}/campaign-tags`, {
    campaign_tag_ids: tag_ids,
  })

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}

export async function deleteCampaignTag(campaignId, tagId) {
  const resp = await axios.delete(`${CAMPAIGN_ENDPOINT}/${campaignId}/campaign-tags/${tagId}`)

  // error handling
  if (!resp.data) {
    throw resp
  }

  return { data: resp.data }
}
